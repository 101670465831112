import $ from 'jquery';
import { ajax, updateBlockModelCallback } from 'OK/utils/utils';
import { parent } from 'OK/utils/dom';
import alf from 'OK/alf';

//Map виджетов лайков на странице
//Для хранения нескольких виджетов на один объект отращиваем массив
var likeWidgets = {},
    setContentInProgress = false;

export default class LikeWidget {
    activate(element) {
        var hookElem = $(element);
        this.hookElem = hookElem;

        this.blockHookId = OK.hookModel.getNearestBlockHookId(element);
        this.blockHook = $('#hook_Block_' + this.blockHookId);
        this.url = this.blockHook.attr('data-url');
        this.wideCnt = false;
        this.noCnt = false;
        this.inactive = false;
        this.redesign2023 = false;

        var flags1 = this.blockHook.attr('data-flags');
        var flags2 = this.hookElem.attr('data-flags');
        if (flags1 || flags2) {
            var flagsArray = (flags1 + ',' + flags2).split(',');
            for (var i = 0; i < flagsArray.length; i++) {
                switch (flagsArray[i]) {
                    case 'wideCnt':
                        this.wideCnt = true;
                        break;
                    case 'noCnt':
                        this.noCnt = true;
                        break;
                    case 'inact':
                        this.inactive = true;
                        break;
                    case 'rdsn2023':
                        this.redesign2023 = true;
                        break;
                }
            }
        }

        this.id1 = hookElem.data('id1');
        this.id2 = hookElem.data('id2') || 0;
        this.idStr = hookElem.data('idStr');
        this.type = hookElem.data('type');
        this.owner = hookElem.data('owner');
        if (this.owner === undefined) {
            this.owner = null; //экономим на передаче null в этот хук.
        }

        this.like = hookElem.data('like') || "on";
        this.count = hookElem.data('count') || 0;

        this.isReshare = 'RESHARE' === this.type;

        this.resharesRestricted = this.blockHook.data('resharesrestricted');

        var canLike = "off" !== hookElem.attr('data-canlike');
        if (this.url && canLike) {
            if (canLike) {
                hookElem.click(click.bind(this));
            }

            if (!this.isReshare) {
                hookElem.mouseenter(hover.bind(this));
            }
        }

        var widget = $('.widget', this.blockHook);
        // аттрибуты на this.blockHook переживают апдейты контента (activate\deactivate хука),
        // поэтому весь "тюнинг" отображения (показывать текст "Класс" или нет и т.п.) должены задаваться на нём.
        widget.toggleClass('__compact', "true" === this.blockHook.attr('data-compact'));
        widget.toggleClass('__no-count', this.noCnt);
        widget.toggleClass('__wide-count', this.wideCnt);
        widget.toggleClass('__null', "true" === hookElem.attr('data-null'));
        widget.toggleClass('__only-icon', "true" === this.blockHook.attr('data-only-icon'));
        widget.toggleClass('__redesign2023', this.redesign2023);
        widget.toggleClass("__inactive", this.inactive);

        //Спрячем шоткат если нет залайкавших
        if (this.isReshare && (this.count == 0)) {
            hookElem.mouseleave();
        }

        add(this);

        // синхронизируем лайки в плеере...
        var playerId = this.blockHook.data('player-id');
        if (playerId) {
            var userLiked = 'off' === this.like;
            runLinkedVideoCallbackFromJS({
                callbackId: playerId,
                liked: userLiked,
                likeCount: this.count
            });
        }
    }

    deactivate(element) {

        var hookElem = $(element);
        hookElem.off();
        hookElem.removeData();

        remove(this);
    }
}


/**
 * Добавляет новый виджет в глобальную мапу и обновляет, если нужно другие виджеты.
 */
function add(self) {
    if ("true" === self.blockHook.attr('data-no-push')) {
        //В некоторых случаях автообновление контента ломает отображение в других леерах.
        return;
    }
    var refId = getReferenceId(self), hooks = likeWidgets[refId];
    if (!hooks) {
        likeWidgets[refId] = hooks = [];
    }

    // Add(self) может вызываться в двух случаях:
    // 1) Подгрузился контент (н-р, автоподгрузка контента в ленте или открытие леер) с лайком
    // 2) Пользователь кликнул на лайк и теперь перерисовываются все такие виджеты
    // В первом случае нужно обновить везде контент,
    // Во-втором - нет.

    //  self.count == -1 - какая-то хитрая штука для видеолайков (флеш?). её не нужно тиражировать.
    if (self.count > -1 && hooks.length > 0 && !setContentInProgress) {
        setContent(refId, self.blockHook.html());
    }

    // Добавляемся в hooks после setContent, что бы не обновлять самого себя.
    hooks.push(self);
}

/**
 * Удаляет виджет из массива
 */
function remove(self) {
    var refId = getReferenceId(self), hooks = likeWidgets[refId];
    if (!hooks || hooks.length === 0) {
        return;
    }
    var index = hooks.indexOf(self);
    if (index > -1) {
        hooks.splice(index, 1);
    }
}

/**
 * Сетит один и тот же контент во все виджеты
 */
function setContent(refId, content) {
    setContentInProgress = true; //говорим, что идёт глобальный апдейт виджетов
    try {

        var hooks = likeWidgets[refId];
        if (!hooks || hooks.length === 0) {
            return;
        }

        var i;

        var blockIds = [];
        for (i = 0; i < hooks.length; i++) {
            blockIds.push(hooks[i].blockHookId); // hooks будет меняться в процессе вызыва OK.hookModel.setHookContent.
        }

        for (i = 0; i < blockIds.length; i++) {
            OK.hookModel.setHookContent(blockIds[i], content);
        }
    } finally {
        setContentInProgress = false;
    }
}

function getReferenceId(self) {
    return self.type + '_' + self.id1 + '_' + self.id2;
}

function hover() {
    sendRequest.call(this, false);
}

function click(e) {
    var uid = e.target.getAttribute('uid');
    if (!OK.isStopped() && !uid) {
        sendRequest.call(this, true);
    }
}

function getImpressionId(hookElem) {
    var current = hookElem.get(0);
    var impressionId;
    var wrapper = parent(current, 'js-video-scope');
    if (wrapper) {
        impressionId = wrapper.getAttribute('data-impression-id');
    }
    if (impressionId) {
        return Promise.resolve(impressionId);
    }

    return alf.findAdv(current).then(function (adv) {
        return adv.getImpressionId();
    });

}

function sendRequest(clicked) {
    var refId = getReferenceId(this),
        isReshare = this.isReshare,
        resharesRestricted = this.resharesRestricted;

    var args,
        needImpressionId = false;

    if (this.hookElem.data('clickFromVideo')) {
        resharesRestricted = 'on';
    }
    this.hookElem.removeData('clickFromVideo');

    if (isReshare) {
        if (!clicked) {
            //Не надо посылать фантомные апдейты для решары
            return;
        }
        //@see one.app.community.dk.blocks.body.layer.reshare.ReshareDataSet st.layer.*** - у решар данные собраны в url ещё на стороне сервера.
        args = {};

    } else {
        //@see two.standard.widgets.like.LikeDataSet st.v.*** - у лайков, данные формируются тут:
        args = {
            'st.v.refId1': this.id1,
            'st.v.refId2': this.id2,
            'st.v.type': this.type,
            'st.v.owner': this.owner,
            'st.v.count': this.count,
            'st.v.resharesRestricted': resharesRestricted
        };
        if (this.idStr) {
            args['st.v.refIdStr'] = this.idStr;
        }

        //Если меню с залайкавшими юзерами сейчас неактивно, это значит, что не надо его рефрешить
        args['st.v.hookId'] = this.blockHookId;
        if (this.blockHook.hasClass('__vis')) {
            args['st.v.hookId'] = this.blockHookId;
        }

        if (clicked) {
            args['st.v.like'] = this.like;
        }
    }

    if (clicked && args['st.v.like'] === 'on') {
        needImpressionId = true;
    }

    var doRequest = (function () {
        ajax({
            type: 'POST',
            url: this.url,
            data: args
        }).done(function (data, status, xhr) {
            if (data == null || data.length === 0) {
                return;
            }

            if (clicked) {
                likeWidgets[refId].forEach(function (hook) {
                    hook._haveDataToSave = false;
                });
            }

            if (isReshare) {
                //Для решары нужно обрабатывать клик обычным образом (открытие леера поделившихся)
                //В леер будет передан в том числе и blockHookId, чтобы после решары сделать редирект на этот блок и обновить всё.
                updateBlockModelCallback(data, status, xhr);

            } else {

                //Для лайков не открывается промежутчного леера, поэтому нужно в "ручную" обновить контент блоков
                //(ответ приходит не на blockHookId).

                //Отделяем первый блок из респонса и запускаем для него механизм рефреша виджетов лайка:
                var index = data.indexOf(OK.navigation.SPLITER),
                    likeContent;

                if (index < 0) {
                    likeContent = data;
                } else {
                    //Если в респонсе пришли еще какие-то блоки (обновление для меню с залайкавшими юзерами),
                    //запустим для них стандартный механизм апдейта блочных хуков
                    likeContent = data.substring(0, index);
                    updateBlockModelCallback(data.substring(index), status, xhr);
                }

                setContent(refId, likeContent);
            }
        });
    }).bind(this);

    if (needImpressionId) {
        getImpressionId(this.hookElem).then(
            function (impressionId) {
                if (impressionId) {
                    args['st.v.impressionId'] = impressionId;
                }
                doRequest();
            },
            doRequest
        )
    } else {
        doRequest();
    }
}
